<template>
    <div>
      <transition name="vload">
        <div v-show="isLoading" class="Loader">
          <div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
          </div>
          <!--<i class="fa fa-spinner fa-spin fa-5x fa-fw" style="color:green"></i>-->
        </div>
      </transition>
        <!-- ============================================================== -->
        <!-- Main wrapper - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <div id="main-wrapper">
            <Header></Header>
            <section class="fixed auth-frame">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-sm-12 mx-auto">
                            <div class="form-title"><h1>パスワード再設定</h1></div>
                            <div v-if="hasValidationError" class="error-area">
                                <p v-for="item in allValidationError" :key="item.validationText">
                                    ・{{item.validationText}}
                                </p>
                            </div>
                            <form @submit.prevent="register">
                              <div class="form-group registered">
                                <label>メールアドレス：</label>
                                <label>{{mailAddress}}</label>
                              </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label>パスワード</label>
                                        <input type="password" class="form-control" v-model="password1">
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label>パスワード（確認用）</label>
                                        <input type="password" class="form-control" v-model="password2">
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <button type="submit" class="btn-md full-width pop-login form-button" :disabled="isRegisterEnabled">登録</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </section>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
    import Header from "@/views/components/Header";
    import Footer from "@/views/components/Footer";
    import MixIn from "@/common/mixin";
    import ApiMixIn from "@/common/api_mixin";

    export default {
        name: "PasswordReissue",
        components: {Footer, Header},
        mixins: [MixIn, ApiMixIn],

        data: function() {
            return {
                mailAddress: "",
                userName: "",
                password1: "",
                password2: "",
                validationErrors: {}
            }
        },
        created: function () {
            const token = this.$route.query.token;
            if (!token) {
                this.pushError("URLが正しくありません。");
                return;
            }
            this.verifyToken(token);
        },
        computed: {
            isRegisterEnabled: {
                get() {
                    return this.username && this.password1 && this.password2;
                },
            }
        },
        methods: {
            async verifyToken(token) {
                const response = await this.postApi("auth/reissueVerifyToken", { token: token });
                if (response) {
                    this.mailAddress = response.data.mailAddress;
                }
            },
            async register() {
                const response = await this.postApi("auth/passwordReissue", {
                    password1: this.password1,
                    password2: this.password2
                }, true);

                if (response) {
                    this.pushResult("再設定完了", "パスワードを再設定しました");
                }
            }
        }
    }
</script>

<style scoped>

</style>
